import { format, parseISO, intervalToDuration, formatDuration } from 'date-fns';

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATETIME_FORMAT = 'dd LLL yyyy, hh:mm a';

export const formatTimestamp = (timestamp: number, template: string = DEFAULT_DATETIME_FORMAT) => {
  return format(new Date(timestamp * 1000), template);
};

export const formatDate = (date: Date, template: string = DEFAULT_DATE_FORMAT) => {
  return format(date instanceof Date ? date : parseISO(date), template);
};

export const getDurationUntil = (endDate: Date) => {
  if (!endDate) return '';

  const now = new Date();

  const duration = intervalToDuration({
    start: now,
    end: endDate > now ? endDate : now,
  });

  const units = ['years', 'months', 'weeks', 'days', 'hours'];
  const nonzero = Object.entries(duration)
    .filter(([_, value]) => value || 0 > 0)
    .map(([unit, _]) => unit);

  return formatDuration(duration, {
    format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 2),
    delimiter: ' ',
  });
};
